import React from "react";
import {Mark, Type, Logo} from "../Components/Logo/logos"
import "../Assets/Style/shared/_content.sass"
import "./about.sass"
import { Link } from 'react-router-dom';
const About = (props: any) =>
  <>
    <div id={"about"} className={"content"}>
      <div className={"logo_cont"}>
      <Logo className={"large"}/>
    </div>
      <div className={"para"}>
      <p>
        Polyomino is a game developer based in Waterloo Ontario, Canada.
        <br/>
        <br/>
        We're to author Play.
      </p>

        <h3>
          contant
        </h3>
        <a href={"mailto:mail@polyomino.co"}>
          <span className={"mail"}>mail</span>
          <span className={"at"}>@</span>
          <span className={"poly"}>polyomino</span>
          <span className={"dot"}>.</span>
          <span className={"com"}>co</span>
        </a>
      </div>
    </div>
  </>

export default About;