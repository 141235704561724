import React from "react";
import { Type } from '../Logo/logos';
import './foot.sass';

const Foot = (props: any) =>
  <>
    <div id="foot">
        © 2020 <Type className={"small"}/>
    </div>
  </>

export default Foot;