import React from 'react';
import { Mark, Type, Logo } from './Components/Logo/logos';
import { Board, Infor, Contact } from './svg/icons';
import './Assets/Style/scss/icons.css';
import './Assets/Style/style.css';
import { BrowserRouter as Router, Route, Link, NavLink } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import Home from "./Page/Home"
import Privacy from "./Page/privacyPolicy"
import Foot from './Components/Foot/foot'
import Head from './Components/Head/head';
import About from "./Page/about"
import {pages, routesarr as routes} from "./Page"
import "./Assets/Style/app.sass"


function App() {
  return (
    <Router>
      <div id="app">
        <div id="content" className="container">
          {routes.map(({ path, Page }) => (
            <Route key={path} exact path={path}>
              {({ match }) => (
                <CSSTransition
                  in={match != null}
                  timeout={300}
                  classNames="page"
                  unmountOnExit
                >
                  <div className="page">
                    <Page/>
                  </div>
                </CSSTransition>
              )}
            </Route>
          ))}
        </div>
        <Foot/>
    </div>
    </Router>
  )
}

export default App;
