import React from "react";
import "./logos.sass";

export const Mark = (props: {className: string}) => (
  <div id="mark" className={props.className}>
    <div className={`sg ${props.className}`}>
      <div className={`s ${props.className}`}></div>
      <div className={`s ${props.className}`} id="s1"></div>
      <div className={`s ${props.className}`} id="s2"></div>
    </div>
    <div className={`sg ${props.className}`}>
      <div className={`s ${props.className}`} id="s3"></div>
      <div className={`s ${props.className}`} id="s4"></div>
      <div className={`s ${props.className}`}></div>
    </div>
  </div>
);

export const Type = (props: {className: string}) => (
  <div id="type" className={props.className}>
    <div id="polyomina">
      <p>
        <span id={"poly"}>Poly</span>
        <span id={"omino"}>omino</span>
      </p>
    </div>
  </div>
);

export const Logo = (props: {className: string}) => (
  <div id="logo">
    <Type className={props.className}></Type>
    <Mark className={props.className}></Mark>
  </div>
);
