import Privacy from "./privacyPolicy";
import About from "./about";
import Home from './Home';

const pages = [About, Privacy]
const routes = {
  home: [{ path: '/', Page: pages[0] }],
  about: [{ path: '/about', Page: pages[1] }],
  other: [{ path: '/privacy', Page: pages[2] }]
};
const routesarr = routes.home.concat(routes.other)
export  {
  routes,
  pages,
  routesarr,
}
