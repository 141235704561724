import React from "react";
import { Link } from "react-router-dom"
const Privacy = () => (
    <div className={"content"}>
      <h2>Privacy Policy</h2>
      <h5>Last updated June 30th 2019 </h5>
  
      <p>
        Monupon Inc ("Polyomina", "Company", "we", "us", or "our") only collects
        information from your submission to join our mailing list, that is, we
        only collect your email address for keeping you updated with new
        developments about our games; this falls under{' '}
        <Link to="https://mailchimp.com/legal/privacy/">
          Mailchimp's privacy policy.
        </Link>
        </p>
      <p>
        We also use{' '}
        <Link to="https://policies.google.com/privacy">Google Analytics</Link> for
        tracking website performance, please refer to Google's privacy policy.
      </p>
      <p>
        Monupon will never sell, exhange, transfer, or share your information with
        anyone. We are committed to protecting your personal information and your
        right to privacy. If you have any questions or concerns please contact us
        at <i> corp@monupon.com</i>
      </p>
    </div>
  );

  export default Privacy;